declare global {
  interface Window {
    env: {
      ONEFID_CORE_API?: string;
      SECRET_KEY?: string;
      SENTRY_DSN?: string;
      SENTRY_ENVIRONMENT?: string;
    };
  }
}
/* tslint:enable:no-any */
export const setEnvVariablesToGlobalState = (): void => {
  window.env = window.env || {};

  window.env = {
    ONEFID_CORE_API:
      window.env.ONEFID_CORE_API || process.env.REACT_APP_ONEFID_CORE_API,
    SECRET_KEY: window.env.SECRET_KEY || process.env.REACT_APP_SECRET_KEY,
    SENTRY_DSN: window.env.SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN,
    SENTRY_ENVIRONMENT:
      window.env.SENTRY_ENVIRONMENT || process.env.SENTRY_ENVIRONMENT,
  };
};
