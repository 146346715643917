import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Spin } from "antd";
import React from "react";

export const Spinner = (): JSX.Element => {
  const SpinnerContainer = styled.div({
    textAlign: "center",
    padding: "30px 50px",
    marginTop: "20vh",
  });
  const antIcon = <LoadingOutlined style={{ fontSize: "6rem" }} />;

  return (
    <SpinnerContainer>
      <Spin indicator={antIcon} />
    </SpinnerContainer>
  );
};
