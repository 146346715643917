// Page Login
import { Form, Col, Row } from "antd";
import { Store } from "antd/lib/form/interface";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";
import { BlueButton } from "../components/Buttons/BlueButton";
import { CustomButton } from "../components/Buttons/Button";
import { CustomInput } from "../components/CustomInput";
import { PopupLayout } from "../components/PopupLayout";
import { ContentWrapper, Text, Title } from "../components/TextLayout";

// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD

export const NewPassword = (props: RouteComponentProps): JSX.Element => {
  const { ...routeProps } = props;
  const [isHidden, setIsHidden] = useState(false);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: 11,
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 24,
      },
    },
  };
  const handleSubmit = (values: Store) => {
    getApiService()
      .postTriggerPasswordReset(values.email)
      .then(() => {
        setIsHidden(true);
      })
      .catch(() => {
        routeProps.history.push("/error");
      });
  };

  return (
    <PopupLayout>
      {!isHidden ? (
        <>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <ContentWrapper style={{ marginBottom: "0" }}>
                <Title style={{ marginBottom: 0 }}>
                  DU HAST DEIN
                  <br />
                  PASSWORT VERGESSEN?
                </Title>
              </ContentWrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <ContentWrapper>
                <Row>
                  <Col xs={24} md={24} sm={24} lg={24} xl={24}>
                    <Text style={{ paddingTop: "10px" }}>
                      Gebe hier deine One Fitting ID ein.
                      <br />
                      Deine One Fitting ID ist die E-Mail, mit der du dich bei
                      OneFID <br />
                      angemeldet hast.
                      <br />
                      Wir senden dir anschließend eine Mail um dein Passwort{" "}
                      <br />
                      neu zu setzen.
                    </Text>
                  </Col>
                </Row>
                <Form
                  {...formItemLayout}
                  onFinish={handleSubmit}
                  name="new-password"
                >
                  <Form.Item
                    hasFeedback
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Das ist keine korrekte E-Mail Adresse.",
                      },
                      {
                        required: true,
                        message: "Bitte gib deine E-Mail Adresse ein.",
                      },
                    ]}
                  >
                    <CustomInput className="EmailBar" placeholder="E-Mail" />
                  </Form.Item>

                  <Form.Item {...tailFormItemLayout}>
                    <BlueButton htmlType="submit" className="ButtonMargin">
                      SENDEN
                    </BlueButton>
                    <a href="/">
                      <CustomButton>ABBRECHEN</CustomButton>
                    </a>
                  </Form.Item>
                </Form>
              </ContentWrapper>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col xs={24} md={24} sm={24} lg={24} xl={24}>
            <ContentWrapper style={{ marginBottom: "0" }}>
              <Text style={{ paddingTop: "10px" }}>
                Wir haben dir eine E-Mail mit Link gesendet. Bitte folge dem
                Link um dein Passwort zu ändern.
              </Text>
            </ContentWrapper>
          </Col>
        </Row>
      )}
    </PopupLayout>
  );
};
