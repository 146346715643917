import styled from "@emotion/styled";
import { Col, Layout as AntdLayout, Row } from "antd";
import React from "react";
import { logoWhite } from "../assets";

const CustomHeader = styled(AntdLayout.Header)({
  backgroundColor: "#011e3b",
  color: "white",
  textAlign: "center",
  height: "150px",
});

export const AccountHeader: React.FC = (): JSX.Element => (
  <Row>
    <Col span={24}>
      <CustomHeader>
        <img
          src={logoWhite}
          alt=""
          style={{ width: "70px", paddingTop: "40px", paddingBottom: "40px" }}
        />
      </CustomHeader>
    </Col>
  </Row>
);
