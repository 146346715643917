import { Row } from "antd";
import "antd/dist/antd.css";
import React, { ReactNode } from "react";
import { BlueButton } from "./Buttons/BlueButton";
// Layout for the Modals on the Account page

// TODO: needs complete refactoring, this is submit button of modals in account page
type Props = {
  readonly children?: ReactNode;
  handleSubmit: (e: Event) => void;
};

export const ModalLayout = ({ children, handleSubmit }: Props): JSX.Element => (
  <Row>
    {children}
    <div style={{ textAlign: "center" }}>
      <BlueButton
        style={{ marginTop: "20px", width: "80%" }}
        onSubmit={handleSubmit}
      >
        Bestätigen
      </BlueButton>
    </div>
  </Row>
);
