// Page Registration:
import styled from "@emotion/styled";
import { Form, Checkbox, Col, Input, Layout, Row } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";
import { Store } from "antd/lib/form/interface";
import { RuleObject } from "antd/lib/form";
import { BlueButton } from "../components/Buttons/BlueButton";
import { CustomButton } from "../components/Buttons/Button";
import { CustomInput } from "../components/CustomInput";
import { PopupLayout } from "../components/PopupLayout";
import { ErrorMessageText, Title } from "../components/TextLayout";

// TODO: Set a right connection for handing over data to API.
// "Weiter"-Button currently can not lead the customer to the /register-done Page.
const CustomLayout = styled(Layout)({
  backgroundColor: "White",
});

const NameInput = styled(Input)({
  borderLeft: "none",
  borderRight: "none",
  borderTop: "none",
  borderRadius: 0,
  width: "190px",
  marginRight: "20px",
  "@media (max-width: 576px)": {
    width: "300px",
    marginRight: 0,
  },
});

const checkCheckBox = (
  rule: RuleObject,
  value: boolean,
  callback: (param?: string) => void
) => {
  if (!value) {
    callback("Bitte stimme der Datenschutzerklärung zu!");
  } else {
    callback();
  }
};

// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD
export const Registration = (props: RouteComponentProps): JSX.Element => {
  const { ...routeProps } = props;
  const [wrongMessageToggle, setWrongMessageToggle] = useState(false);
  const [errorMessages, setErrorMessages] = useState();
  const formItemLayout = {
    labelCol: {
      xs: 24,
      sm: 24,
      md: 24,
    },
    wrapperCol: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 11,
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 24,
      },
    },
  };

  // In the following line (e: ChangeEvent<HTMLInputElement>) was changed to (e: any) to remove the error (WIP!)
  const handleSubmit = (values: Store) => {
    const {
      email,
      firstName,
      lastName,
      agreementterms,
      agreementsubscribe,
      password,
      confirm,
    } = values;
    getApiService()
      .postRegisterUser(
        email,
        firstName,
        lastName,
        agreementterms,
        agreementsubscribe,
        password,
        confirm
      )
      .then(() => {
        getApiService()
          .fetchLogin(email, password)
          .then(() => {
            routeProps.history.push("/register-done");
          });
      })
      .catch((error) => {
        setWrongMessageToggle(true);
        setErrorMessages(error);
      });
  };

  return (
    <PopupLayout>
      <CustomLayout>
        <Row>
          <Col
            xs={24}
            md={24}
            sm={24}
            lg={24}
            xl={24}
            style={{ textAlign: "left", margin: "0 3%" }}
          >
            <Title style={{ marginBottom: 0 }}>
              ERSTELLE DEINE <br /> ONE FITTING ID
            </Title>
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            md={24}
            sm={24}
            lg={24}
            xl={24}
            style={{ textAlign: "left", margin: "0 3%" }}
          >
            <Form {...formItemLayout} name="register" onFinish={handleSubmit}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Bitte fülle das Feld aus.",
                  },
                ]}
                style={{ display: "inline-block", marginBottom: "14px" }}
              >
                <NameInput
                  className="firstName"
                  placeholder="Vorname"
                  name="android:fitsSystemWindows"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Bitte fülle das Feld aus.",
                  },
                ]}
                style={{ display: "inline-block", marginBottom: "14px" }}
              >
                <NameInput
                  className="lastName"
                  placeholder="Nachname"
                  name="android:fitsSystemWindows"
                />
              </Form.Item>
              <Form.Item
                hasFeedback
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Das ist keine korrekte E-Mail Adresse.",
                  },
                  {
                    required: true,
                    message: "Bitte fülle das Feld aus.",
                  },
                ]}
                style={{ marginBottom: "14px" }}
              >
                <CustomInput
                  className="EmailBar"
                  placeholder="E-Mail"
                  name="android:fitsSystemWindows"
                />
              </Form.Item>
              <Form.Item
                hasFeedback
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Bitte gib dein neues Passwort ein.",
                  },
                  {
                    required: true,
                    // the regex pattern below checks if:
                    // Your password is at least 8 characters long.
                    // Your password contains at least one number.
                    // Your password contains at least one letter.
                    // Your password contains at least one special character
                    pattern: /^(?=\S*[A-Za-z])(?=\S*\d)(?=\S*[^\s\w])\S{8,}$/,
                    message:
                      "Das Passwort muss mindestens 8 Zeichen, Buchstaben, Zahlen und Sonderzeichen enthalten.",
                  },
                ]}
                style={{ marginBottom: "14px" }}
              >
                <CustomInput type="Password" placeholder="Passwort" />
              </Form.Item>
              <Form.Item
                hasFeedback
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Bitte bestätige dein Passwort.",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value && getFieldValue("password") !== value) {
                        return Promise.reject(
                          new Error(
                            "Die beiden eingegebenen Passwörter sind nicht identisch!"
                          )
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
                style={{ marginBottom: "14px" }}
              >
                <CustomInput
                  type="Password"
                  placeholder="Passwort bestätigen"
                />
              </Form.Item>
              <Form.Item
                {...tailFormItemLayout}
                name="agreementterms"
                valuePropName="checked"
                rules={[
                  {
                    validator: checkCheckBox,
                  },
                ]}
                style={{ marginBottom: "14px" }}
              >
                <Checkbox>
                  Ich stimme den{" "}
                  <a href="https://onefid.com/datenschutzerklaerung/">
                    <strong style={{ color: "black" }}>
                      Datenschutzbestimmungen{" "}
                    </strong>
                  </a>
                  zu.
                </Checkbox>
              </Form.Item>
              {wrongMessageToggle &&
                (errorMessages
                  ? errorMessages.map((code: string) => {
                      return (
                        <ErrorMessageText key={code} style={{ color: "red" }}>
                          {code}
                        </ErrorMessageText>
                      );
                    })
                  : undefined)}
              <Form.Item
                {...tailFormItemLayout}
                style={{ marginBottom: "14px" }}
              >
                <BlueButton htmlType="submit" className="ButtonMargin">
                  WEITER
                </BlueButton>
                <CustomButton onClick={() => routeProps.history.push("/")}>
                  ABBRECHEN
                </CustomButton>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </CustomLayout>
    </PopupLayout>
  );
};
