// Page when Account already is activated
import { Col, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BlueButton } from "../components/Buttons/BlueButton";
import { PopupLayout } from "../components/PopupLayout";
import { ContentWrapper, Text, Title } from "../components/TextLayout";

// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD

export const AlreadyActivated = (
  routeProps: RouteComponentProps
): JSX.Element => (
  <PopupLayout>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper style={{ marginBottom: "0" }}>
          <Title>
            DEINE ONE FITTING ID
            <br />
            IST BEREITS AKTIVIERT
          </Title>
        </ContentWrapper>
      </Col>
    </Row>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper>
          <Text style={{ paddingTop: "10px" }}>Klicke auf Weiter</Text>
        </ContentWrapper>
      </Col>
    </Row>
    <ContentWrapper>
      <BlueButton onClick={() => routeProps.history.push("/login/")}>
        WEITER
      </BlueButton>
    </ContentWrapper>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper>
          <Text style={{ paddingTop: "10px" }}>
            Du benötigst Hilfe?
            <br />
            Schreib uns unter &nbsp;
            <a
              href="mailto: info@onefid.com"
              style={{ color: "black", fontWeight: "bold" }}
            >
              info@onefid.com
            </a>
          </Text>
        </ContentWrapper>
      </Col>
    </Row>
  </PopupLayout>
);
