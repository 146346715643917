import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Col, Layout as AntdLayout, Row } from "antd";
import React from "react";
import { footerLogoWhite } from "../assets";

const CustomFooter = styled(AntdLayout.Footer)({
  backgroundColor: "#011e3b",
  color: "white",
  textAlign: "center",
});

const Img = styled.img({
  margin: "5% 0",
  width: "20%",
  "@media (max-width: 900px)": {
    width: "30%",
  },
  "@media (max-width: 786px)": {
    width: "35%",
  },
});

const ExternalLink = styled.a({
  color: "#fff",
  textDecoration: "underline",
  padding: "0 5px",
});

/* The class "App-Footer" has a text and three buttons. The footer appears at the end of the website.
The Footer is sticky. The href Tag specified the linked Site. */
type Props = {
  showLogo?: boolean;
};

export const Footer: React.FC = (): JSX.Element => (
  <CustomFooter>
    <Img src={footerLogoWhite} alt="logo" />
    <Row>
      <Col span={24} style={{ marginBottom: "30px" }}>
        <Button
          className="FooterIcons"
          type="link"
          target="_blank"
          ghost
          icon={<InstagramOutlined />}
          href="https://www.instagram.com/onefidgmbh/?hl=de"
        />
        <Button
          className="FooterIcons"
          type="link"
          target="_blank"
          ghost
          icon={<FacebookOutlined />}
          href="https://de-de.facebook.com/onefidgmbh/"
        />
        <Button
          className="FooterIcons"
          type="link"
          target="_blank"
          ghost
          icon={<LinkedinOutlined />}
          href="https://de.linkedin.com/company/onefid"
        />
      </Col>
    </Row>
    Copyright © 2019 OneFID. All rights reserved.
    <div style={{ marginBottom: 10, marginTop: 10 }}>
      <ExternalLink href="/faq" target="_blank" rel="noopener noreferrer">
        FAQ
      </ExternalLink>
      <ExternalLink
        href="mailto: info@onefid.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Kontakt
      </ExternalLink>
      <ExternalLink
        href="https://onefid.com/impressum/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Impressum
      </ExternalLink>
      <ExternalLink
        href="https://onefid.com/datenschutz-plattform/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Datenschutzerklärung
      </ExternalLink>
    </div>
  </CustomFooter>
);
