import { init } from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { setEnvVariablesToGlobalState } from "shared/src/providers/Environment";
import { withTracker } from "shared/src/components/GoogleAnalytics";
import { App } from "./App";
import "./index.scss";

ReactGA.initialize("UA-124187053-4");
setEnvVariablesToGlobalState();
// LOAD SENTRY ON GIVEN KEY
if (window.env.SENTRY_DSN)
  init({
    dsn: window.env.SENTRY_DSN,
    environment: window.env.SENTRY_ENVIRONMENT,
  });

ReactDOM.render(
  <Router>
    <Route component={withTracker(App)} path="/" />
  </Router>,
  document.querySelector("#root")
);
