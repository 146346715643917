export const parseQueryParams = (search: string): any => {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  const params: any = {};
  hashes.forEach((hash) => {
    const [key, val] = hash.split("=");
    params[key] = decodeURIComponent(val);
  });

  return params;
};
