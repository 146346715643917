import styled from "@emotion/styled";
import { Button, Col, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  background,
  checkMarkCircle,
  comingSoon,
  logoBig,
  logoWhite,
} from "../assets";
import { Footer } from "../components/Footer";
import { ParagraphTitle } from "../components/Paragraphs/ParagraphTitle";

// Official Homepage

const width = window.innerWidth;

const Container = styled.div({
  diplay: "flex",
  width: `${width}`,
  flexWrap: "wrap",
  flexDirection: "column",
});
const Section1 = styled.div({
  display: "flex",
  flexWrap: "wrap",
  minHeight: "100vh",
  flexDirection: "column",
  backgroundImage: `url(${background})`,
  backgroundPositionX: "6%",
  objectFit: "contain",
  backgroundSize: "cover",
});

const Section2 = styled.div({
  display: "flex",
  flexWrap: "wrap",
  height: "1078px",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "space-evenly",
  marginLeft: "5%",
  marginRight: "5%",
});

const Section3 = styled.div({
  display: "flex",
  height: "1097px",
  backgroundImage: `url(${comingSoon})`,
  backgroundPositionX: "6%",
  backgroundSize: "cover",
});

const HeaderWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  height: "15%",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "4.5%",
  paddingRight: "2%",
  paddingLeft: "2%",
});

const ButtonContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
});

const CustomButton = styled(Button)({
  backgroundColor: "#001f3b",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
  color: "white",
  zIndex: 10,
  textAlign: "center",
  width: "12.5390625vw",
  minWidth: "8.5546875vw",
  height: "3.1640625vw",
  borderRadius: "37px",
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: 1.5,
  "@media (max-width: 992px)": {
    fontSize: "15px",
  },
});

const TitleContainer = styled.div({
  flexDirection: "column",
  alignSelf: "center",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  marginTop: "6%",
});

const ParagraphText = styled.p({
  textAlign: "center",
  fontSize: "34px",
  color: "black",
  lineHeight: "2em",
  fontWeight: 400,
  "@media (max-width: 786px)": {
    fontSize: "16px",
    textAlign: "center",
  },
});

const LogoContainer = styled.img({
  width: "6.09375vw",
  height: "6.09375vw",
});
const IconContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

export const HomePage = (routeProps: RouteComponentProps): JSX.Element => {
  const handleLoginClick = () => {
    routeProps.history.push("/login/");
  };
  const handleRegisterClick = () => {
    routeProps.history.push("/register");
  };

  return (
    <Container>
      <Section1>
        <Row>
          <Col xs={0} sm={0} md={24} xl={24}>
            <HeaderWrapper>
              <LogoContainer src={logoWhite} />
              <ButtonContainer>
                <CustomButton
                  style={{ margin: "20px" }}
                  onClick={handleRegisterClick}
                >
                  {" "}
                  Registrieren{" "}
                </CustomButton>
                <CustomButton onClick={handleLoginClick}> Login </CustomButton>
              </ButtonContainer>
            </HeaderWrapper>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <TitleContainer>
              <ParagraphTitle>DEINE</ParagraphTitle>
              <ParagraphTitle>ONE FITTING ID</ParagraphTitle>
            </TitleContainer>
          </Col>
          <Col xs={24} sm={24} md={0} xl={0}>
            <ButtonContainer
              style={{
                width: "100%",
                height: "50%",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <CustomButton
                onClick={handleRegisterClick}
                style={{
                  margin: "20px",
                  fontSize: "18px",
                  fontWeight: "normal",
                  width: "60%",
                  height: "45%",
                }}
              >
                Registrieren
              </CustomButton>

              <CustomButton
                onClick={handleLoginClick}
                style={{
                  fontSize: "18px",
                  fontWeight: "normal",
                  width: "60%",
                  height: "45%",
                }}
              >
                Login
              </CustomButton>
            </ButtonContainer>
          </Col>
        </Row>
      </Section1>

      <Section2>
        <img className="LogoBlack" src={logoBig} alt="Logo" />
        <ParagraphText>
          Deine One Fitting ID ermöglicht dir dein perfektes Shopping Erlebnis -
          online
          <br />
          und offline.
        </ParagraphText>
        <div>
          <Row>
            <Col xs={24} sm={24} md={2} xl={2}>
              <IconContainer>
                <img
                  style={{ width: "2vw", minWidth: "25px" }}
                  src={checkMarkCircle}
                  alt="check mark"
                />
              </IconContainer>
            </Col>
            <Col xs={24} sm={24} md={22} xl={22}>
              <ParagraphText>
                Finde deine Lieblingsschuhe immer in der passenden Größe.
              </ParagraphText>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={2} xl={2}>
              <IconContainer>
                <img
                  style={{ width: "2vw", minWidth: "25px" }}
                  src={checkMarkCircle}
                  alt="check mark circle"
                />
              </IconContainer>
            </Col>
            <Col xs={24} sm={24} md={22} xl={22}>
              <ParagraphText>
                Ein Mal vermessen, kannst du bei vielen Partnern einkaufen.
              </ParagraphText>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={2} xl={2}>
              <IconContainer>
                <img
                  style={{ width: "2vw", minWidth: "25px" }}
                  src={checkMarkCircle}
                  alt="check mark circle"
                />
              </IconContainer>
            </Col>
            <Col xs={24} sm={24} md={22} xl={22}>
              <ParagraphText>
                Spare dir lästige Retouren und schone dabei die Umwelt.
              </ParagraphText>
            </Col>
          </Row>
        </div>
        <ParagraphText>
          <b>
            Erstelle noch heute deine persönliche One Fitting ID und nutze alle
            Vorteile deiner digitalen Fußmaße.
          </b>
        </ParagraphText>
      </Section2>

      <Section3 />

      <Footer />
    </Container>
  );
};
