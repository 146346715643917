import styled from "@emotion/styled";
import { Col, Layout, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { accountD, accountLock, accountScale, accountUser } from "../assets";
import { AccountFooter } from "../components/AccountFooter";
import { AccountHeader } from "../components/AccountHeader";
import { PopupButton as ModalButton } from "../components/Buttons/ModalButton";
import { ModalButtonFootData } from "../components/Buttons/ModalButtonFootData";
import { ModalButtonPassword } from "../components/Buttons/ModalButtonPassword";
import { ModalButtonOneFid } from "../components/Buttons/ModalButtonOneFid";
// This page appears when the user clicks on My Account on the profile page

const Content = styled(Layout.Content)({
  textAlign: "center",
  fontSize: "14px",
});

export const AccountPageDetail: React.FC = (): JSX.Element => (
  <Layout>
    <AccountHeader />
    <Content>
      <Row className="AccountBodyWhite" justify="center" align="middle">
        <Col xs={1} sm={1} md={1} lg={1} xl={1} />

        <Col
          xs={22}
          sm={22}
          md={23}
          lg={23}
          xl={23}
          className="AccountDescription"
        >
          <strong>
            <div style={{ color: "black", fontSize: "22px" }}>Dein Account</div>
          </strong>
          <div style={{ color: "black", fontSize: "16px" }}>
            Hier kannst du jederzeit deine Accountdaten ändern.
          </div>
        </Col>

        <Col xs={1} sm={1} md={0} lg={0} xl={0} />
      </Row>

      <Row className="AccountBodyGrey" justify="center" align="middle">
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <img src={accountD} alt="" />
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11} className="AccountText">
          <strong>
            <div style={{ color: "#a0a0a0" }}>Deine One Fitting ID</div>
            {/* Place API-Call for Email below */}
            <div style={{ color: "black" }}>PLACEHOLDER</div>
          </strong>
        </Col>

        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="AccountText">
          {/* This Col stays empty for Desktop-Version. See MockUp */}
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          {/* This Col is reserved for the "Bearbeiten"-Button */}

          <ModalButtonOneFid />
        </Col>
      </Row>

      <Row className="AccountBodyWhite" justify="center" align="middle">
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <img src={accountScale} alt="" />
        </Col>

        <Col xs={12} sm={12} md={11} lg={11} xl={11} className="AccountText">
          <strong>
            <div style={{ color: "#a0a0a0" }}>Foot Data</div>
            {/* Place API-Call for Foot Data below. Default "---" */}
            <div style={{ color: "black" }}>PLACEHOLDER</div>
          </strong>
        </Col>

        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="AccountText">
          <strong>
            <div style={{ color: "#a0a0a0" }}>Datentyp</div>
            {/* Place API-Call for Data Type below. Default "---" */}
            <div style={{ color: "black" }}>PLACEHOLDER</div>
          </strong>
        </Col>

        {/* This Col is reserved for the "Bearbeiten"-Button */}
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <ModalButtonFootData />
        </Col>
      </Row>

      <Row className="AccountBodyGrey" justify="center" align="middle">
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <img src={accountUser} alt="" />
        </Col>

        <Col xs={12} sm={12} md={11} lg={11} xl={11} className="AccountText">
          <strong>
            <div style={{ color: "#a0a0a0" }}>Name</div>
            {/* Place API-Call for Name below. Default "---" */}
            <div style={{ color: "black" }}>PLACEHOLDER</div>
            <br />
            <div style={{ color: "#a0a0a0" }}>Geschlecht</div>
            {/* Place API-Call for Gender below. Default "---" */}
            <div style={{ color: "black" }}>PLACEHOLDER</div>
          </strong>
        </Col>

        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="AccountText">
          <strong>
            <br />
            <br />
            <br />
            <div style={{ color: "#a0a0a0" }}>Geburtsdatum</div>
            {/* Place API-Call for Birthdate below. Default "---" */}
            <div style={{ color: "black" }}>PLACEHOLDER</div>
          </strong>
        </Col>

        {/* This Col is reserved for the "Bearbeiten"-Button */}
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <ModalButton />
        </Col>
      </Row>

      <Row className="AccountBodyWhite" justify="center" align="middle">
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <img src={accountLock} alt="" />
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11} className="AccountText">
          <strong>
            <div style={{ color: "#a0a0a0" }}>Dein Passwort</div>
            {/* Place API-Call for Password below. Censor with "****" <-- Length of PW */}
            <div style={{ color: "black" }}>PLACEHOLDER</div>
          </strong>
        </Col>

        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="AccountText" />

        {/* This Col is reserved for the "Bearbeiten"-Button */}
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <ModalButtonPassword />
        </Col>
      </Row>
    </Content>
    <AccountFooter />
  </Layout>
);
