// Page Login
import { Form, Checkbox, Col, Row } from "antd";
import { Store } from "antd/lib/form/interface";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";
import { getUserTokenFromStorage } from "shared/src/utils/storage-variables";
import { BlueButton } from "../components/Buttons/BlueButton";
import { CustomButton } from "../components/Buttons/Button";
import { CustomInput } from "../components/CustomInput";
import { PopupLayout } from "../components/PopupLayout";
import { Title } from "../components/TextLayout";

// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD
// Login Page

export const Login = (props: RouteComponentProps): JSX.Element => {
  const { ...routeProps } = props;
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 24,
      },
    },
  };

  if (getUserTokenFromStorage()) {
    routeProps.history.push("/profile");
  }

  const handleSubmit = (values: Store) => {
    getApiService()
      .fetchLogin(values.email, values.password)
      .then(() => {
        routeProps.history.push("/profile");
      })
      .catch(() => {
        setWrongCredentials(true);
      });
  };

  return (
    <PopupLayout>
      <Row>
        <Col
          xs={24}
          md={24}
          sm={24}
          lg={24}
          xl={24}
          style={{ textAlign: "left", margin: "0 3%" }}
        >
          <Title style={{ marginBottom: 0 }}>
            MELDE DICH MIT DEINER
            <br />
            ONE FITTING ID AN
          </Title>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          md={24}
          sm={24}
          lg={24}
          xl={24}
          style={{ textAlign: "left", margin: "0 3%" }}
        >
          <p style={{ fontSize: "10px" }}>
            Deine One Fitting ID ist die E-Mail mit der du dich bei OneFID
            angemeldet hast.
          </p>
          <Form {...formItemLayout} onFinish={handleSubmit} name="login">
            <Form.Item
              hasFeedback
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Das ist keine korrekte E-Mail Adresse.",
                },
                {
                  required: true,
                  message: "Bitte fülle das Feld aus.",
                },
              ]}
            >
              {/* 
                name="android:fitsSystemWindows" to fix issue with mobile firefox
                affecting the layout when keyboard is loaded 
                */}
              <CustomInput
                className="EmailBar"
                placeholder="One Fitting ID"
                name="android:fitsSystemWindows"
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              name="password"
              rules={[
                {
                  required: true,
                  message: "Bitte fülle das Feld aus.",
                },
              ]}
            >
              <CustomInput
                type="password"
                placeholder="Passwort"
                name="android:fitsSystemWindows"
              />
            </Form.Item>
            {wrongCredentials ? (
              <p style={{ color: "red" }}>
                Du hast eine ungültige E-Mail oder ein ungültiges Passwort
                eingegeben.
              </p>
            ) : undefined}
            <Form.Item
              {...tailFormItemLayout}
              name="remember"
              initialValue={false}
            >
              <Checkbox style={{ paddingLeft: "5px" }}>
                Angemeldet bleiben
              </Checkbox>
              <a
                className="login-form-forgot"
                style={{ color: "black", padding: "0 1%" }}
                href="/newpw"
              >
                Passwort vergessen?
              </a>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <BlueButton htmlType="submit" className="ButtonMargin">
                WEITER
              </BlueButton>
              <CustomButton onClick={() => routeProps.history.push("/")}>
                ABBRECHEN
              </CustomButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </PopupLayout>
  );
};
