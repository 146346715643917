/* eslint-disable no-console */
import { UserSelection, FootMeasurements, FootRecommendation } from "../../../plugin/src/providers/Context";
import { Permissions, Statistics } from "../../../partner/src/providers/Interfaces";

const helperMessage =
  "Some application functionalities might be impaired. Please enable third party cookies for full functionality.";

export const setItemInSessionStorage = (
  item: string | UserSelection | Permissions | Statistics | FootMeasurements | FootRecommendation,
  identifier: string
): void => {
  try {
    sessionStorage.setItem(identifier, JSON.stringify(item));
  } catch {
    console.log(
      `Error on setting ${identifier} in sessionStorage. ${helperMessage}`
    );
  }
};

const setItemInLocalStorage = (
  item: string | UserSelection,
  identifier: string
): void => {
  try {
    localStorage.setItem(identifier, JSON.stringify(item));
  } catch {
    console.log(
      `Error on setting ${identifier} in localStorage. ${helperMessage}`
    );
  }
};

export const setUserSelectionInSessionStorage = (item: UserSelection): void =>
  setItemInSessionStorage(item, "userSelection");

export const setEmailInSessionStorage = (item: string): void =>
  setItemInSessionStorage(item, "email");

export const setEmailInLocalStorage = (item: string): void =>
  setItemInLocalStorage(item, "email");

export const setPartnerNameInSessionStorage = (item: string): void =>
  setItemInSessionStorage(item, "partnerName");

export const setUserTokenInSessionStorage = (item: string): void =>
  setItemInSessionStorage(item, "userToken");

export const setUserTokenInLocalStorage = (item: string): void =>
  setItemInLocalStorage(item, "userToken");

export const setPartnerIdInSessionStorage = (item: string): void =>
  setItemInSessionStorage(item, "partnerId");

export const setPermissionsInSessionStorage = (item: Permissions): void =>
  setItemInSessionStorage(item, "permissions");

  export const setPartnerPluginSdkUrlInSessionStorage = (item: string): void =>
  setItemInSessionStorage(item, "sdkUrl");

const getSessionStorageError = `Error on getting given item in sessionStorage. ${helperMessage}`;

export const getItemFromSessionStorage = (
  identifier: string
): string | UserSelection | Statistics | null => {
  try {
    return JSON.parse(sessionStorage.getItem(identifier) || "null");
  } catch {
    console.log(getSessionStorageError);

    return null;
  }
};

export const getUserTokenFromStorage = (): string | null => {
  try {
    return JSON.parse(
      localStorage.getItem("userToken") ||
      sessionStorage.getItem("userToken") ||
      "null"
    );
  } catch {
    console.log(getSessionStorageError);

    return null;
  }
};

export const getUserSelectionFromSession = (): UserSelection | null =>
  getItemFromSessionStorage("userSelection") as UserSelection | null;

export const getEmailFromStorage = (): string | null => {
  try {
    return JSON.parse(
      localStorage.getItem("email") || sessionStorage.getItem("email") || "null"
    );
  } catch {
    console.log(getSessionStorageError);

    return null;
  }
};

export const getPartnerNameFromSessionStorage = (): string | null =>
  getItemFromSessionStorage("partnerName") as string | null;

export const getPartnerIdFromSessionStorage = (): string | null =>
  getItemFromSessionStorage("partnerId") as string | null;

export const getPermissionsFromSessionStorage = (): Permissions | null =>
  getItemFromSessionStorage("permissions") as Permissions | null;

export const getMobileMeasurementsStatisticsFromSessionStorage = (): Statistics | null =>
  getItemFromSessionStorage("statistics") as Statistics | null;

  export const getPartnerPluginSdkUrlInSessionStorage = (): string | null =>
  getItemFromSessionStorage("sdkUrl") as string | null;

export const removeItemFromStorage = (identifier: string): void => {
  try {
    localStorage.removeItem(identifier);
    sessionStorage.removeItem(identifier);
  } catch {
    console.log(`Error on removing ${identifier} in browser storage.`);
  }
};
