import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import { getUserTokenFromStorage } from "shared/src/utils/storage-variables";
import "./App.scss";
import { Activation } from "./components/Activation";
import { AccountPageDetail } from "./pages/AccountPageDetail";
import { ActivationDone } from "./pages/ActivationDone";
import { AlreadyActivated } from "./pages/AlreadyActivated";
import { AlreadySetPw } from "./pages/AlreadySetPw";
import { Error404 } from "./pages/Error404";
import { ErrorPage } from "./pages/ErrorPage";
import { ExpiredLink } from "./pages/ExpiredLink";
import { HomePage } from "./pages/HomePage";
import { Login } from "./pages/Login";
import { NewPassword } from "./pages/NewPw";
import { Profile } from "./pages/Profile";
import { Registration } from "./pages/Registration";
import { RegistrationDone } from "./pages/RegistrationDone";
import { SetPassword } from "./pages/SetPw";
import { FAQ } from "./pages/Faq";

export const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/activation" component={Activation} />
        <Route path="/activation-expired" component={ExpiredLink} />
        <Route path="/activation-done" component={ActivationDone} />
        <Route path="/activation-existing" component={AlreadyActivated} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/register" component={Registration} />
        <Route path="/faq" component={FAQ} />
        <Route
          path="/profile"
          render={(props: RouteComponentProps) =>
            getUserTokenFromStorage() ? (
              <Profile {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route path="/setpw" component={SetPassword} />
        <Route path="/alreadysetpw" component={AlreadySetPw} />
        <Route path="/not-found" component={Error404} />
        <Route path="/account" component={AccountPageDetail} />
        <Route path="/login" component={Login} />
        <Route path="/newpw" component={NewPassword} />
        <Route path="/register-done" component={RegistrationDone} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};
