import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Col, Layout as AntdLayout, Row } from "antd";
import React from "react";
import { oneLogo } from "../assets";

const CustomFooter = styled(AntdLayout.Footer)({
  backgroundColor: "#021d3b",
  color: "white",
  textAlign: "center",
  position: "sticky",
});

/* The class "AccountFooter" has a text, three Social-Icons and three buttons. The footer appears at the end of the website. */

export const AccountFooter: React.FC = (): JSX.Element => (
  <CustomFooter>
    <Row>
      <Col span={24} style={{ marginTop: "40px", marginBottom: "40px" }}>
        <img src={oneLogo} alt="" />
      </Col>
    </Row>
    <Row>
      <Col span={24} style={{ marginBottom: "30px" }}>
        <Button
          className="FooterIcons"
          type="link"
          ghost
          icon={<InstagramOutlined />}
          href="https://www.instagram.com/onefidgmbh/?hl=de"
        />
        <Button
          className="FooterIcons"
          type="link"
          ghost
          icon={<FacebookOutlined />}
          href="https://de-de.facebook.com/onefidgmbh/"
        />
        <Button
          className="FooterIcons"
          type="link"
          ghost
          icon={<LinkedinOutlined />}
          href="https://de.linkedin.com/company/onefid"
        />
      </Col>
    </Row>
    <Row>
      <Col span={24}>Copyright © 2019 OneFID. All rights reserved.</Col>
    </Row>
    <Row>
      <Col span={24}>
        <Button className="FooterLinks" type="link" ghost href="/faq">
          FAQ
        </Button>
        <Button
          className="FooterLinks"
          type="link"
          ghost
          href="mailto: info@onefid.com"
        >
          Kontakt
        </Button>
        <Button
          className="FooterLinks"
          type="link"
          ghost
          href="https://onefid.com/impressum/"
        >
          Impressum
        </Button>
        <Button
          className="FooterLinks"
          type="link"
          ghost
          href="https://onefid.com/datenschutzerklaerung/"
        >
          Datenschutzerklärung
        </Button>
      </Col>
    </Row>
  </CustomFooter>
);
