import styled from "@emotion/styled";

export const BackgroundWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#19232c",
  position: "relative",
  height: "100vh",
  width: "100%",
  overflowX: "hidden",
  "@media (max-width: 786px)": {
    backgroundColor: "white",
  },
});

export const MainWrapper = styled.div({
  alignItems: "left",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "7%",
  paddingRight: "7%",
  paddingTop: "4%",
  paddingBottom: "4%",
});

export const XWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  float: "right",
  paddingRight: "1vh",
  paddingTop: "1vh",
});

export const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  float: "right",
});

export const FooterWrapper = styled.div({
  position: "sticky",
  objectFit: "contain",
  backgroundColor: "#ebebeb",
  width: "auto",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.5,
  letterSpacing: "normal",
  padding: "1%",
  color: "#000000",
  display: "flex",
  alignItems: "center",
  "@media (max-width: 786px)": {
    display: "grid",
    margin: "-27%",
    marginTop: "38%",
    width: "150%",
  },
});

export const CopyWrapper = styled.div({
  paddingLeft: "1%",
  fontSize: "14.64px",
});
