/* eslint-disable no-use-before-define */
import { PoweroffOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Col, Divider, Row, Slider } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";
import { getUserTokenFromStorage } from "shared/src/utils/storage-variables";
import {
  footGrid,
  footLength,
  footWidth,
  iosAppStore,
  androidAppStore,
  logoBig,
} from "../assets";
import { Footer } from "../components/Footer";

// Offical Profile Page for the user

const Wrapper = styled.div({
  display: "flow-root",
  color: "black",
  "@media (max-width: 768px)": {
    marginLeft: "25px",
    marginRight: "25px",
  },
});

const ContentWrapper = styled(Wrapper)({
  textAlign: "center",
  "@media (max-width: 768px)": {
    fontSize: "18px",
  },
});

const TextWrapper = styled(Wrapper)({
  textAlign: "left",
  "@media (max-width: 768px)": {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "bold",
  },
});

const Title = styled.h1({
  fontSize: "35px",
  fontWeight: 800,
  "@media (max-width: 768px)": {
    fontSize: "26px",
  },
});

const BottomText = styled.h4({
  fontSize: "22px",
  fontWeight: 900,
  "@media (max-width: 768px)": {
    fontSize: "17px",
  },
});

const CustomRow = styled(Row)({
  fontWeight: "bolder",
  marginBottom: "2%",
  "@media (max-width: 768px)": {
    marginBottom: "10%",
    fontWeight: "normal",
  },
});

const CustomCol = styled(Col)({
  "@media (max-width: 768px)": {
    marginBottom: "10%",
    textAlign: "center",
  },
});

const CustomButton = styled(Button)({
  marginTop: "10px",
  fontSize: "14px",
});

const marks = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
};

const RowWithOpacity = styled(Row)({
  opacity: "0.1",
});

type Measurements = {
  leftBallWidth: number;
  leftFootLength: number;
  leftWidthCategory: number;
  rightBallWidth: number;
  rightFootLength: number;
  rightWidthCategory: number;
};

export const Profile = (props: RouteComponentProps): JSX.Element => {
  const { ...routeProps } = props;
  // if true is applied the no-data-case is shown
  const [isHidden, setIsHidden] = useState(false);
  const [measurements, setMeasurements] = useState<Measurements>({
    leftBallWidth: 0,
    leftFootLength: 0,
    leftWidthCategory: 0,
    rightBallWidth: 0,
    rightFootLength: 0,
    rightWidthCategory: 0,
  });

  // UseEffect can not compare objects when deciding to rerender
  // Therefore we transform measurements object into a string
  // and pass it to useEffect's dependency array
  const stringifiedMeasurements = JSON.stringify(measurements);

  const handleLogoutClick = () => {
    getApiService()
      .fetchLogout()
      .then(() => {
        routeProps.history.push("/");
      });
  };

  useEffect(() => {
    const getUserProfileData = async () => {
      if (getUserTokenFromStorage()) {
        const user = await getApiService().fetchUserProfile();
        getApiService()
          .fetchUserMeasurements(user.email)
          .then((response) => {
            let clonedMeasurements: Measurements = {
              ...JSON.parse(stringifiedMeasurements),
            };
            // TODO: Evaluate if this can be solved with map + filter + reduce
            ["left", "right"].map((side: string) => {
              return ["standing", "sitting"].map((position: string) => {
                if (!response[side][position]) {
                  return 0;
                }

                return [
                  response[side][position].width_ball,
                  response[side][position].length_foot,
                  response[side][position].width_category,
                ].map((rawSize: number, index: number) => {
                  let convertedSize = rawSize;
                  // Convert width and length but not width category
                  if (index !== 2) {
                    convertedSize = Math.floor(rawSize) / 10;
                  }

                  return Object.keys(clonedMeasurements).map(
                    (key: string, i: number) => {
                      // The api has 3 keys per side.
                      // We store 6 keys in the state.
                      const normalizedIndex: number =
                        side === "left" ? index : index + 3;
                      if (i === normalizedIndex) {
                        clonedMeasurements = {
                          ...clonedMeasurements,
                          [key]: convertedSize,
                        };
                        // TODO: Consider returning only the key and the value
                        // Create the measurements object in the upper scope

                        return clonedMeasurements;
                      }

                      return clonedMeasurements;
                    }
                  );
                });
              });
            });

            setMeasurements(clonedMeasurements);
          })
          .then(() => {
            if (
              Object.values(JSON.parse(stringifiedMeasurements)).every(
                (value) => {
                  return !value;
                }
              )
            ) {
              setIsHidden(true);
            } else {
              setIsHidden(false);
            }
          })
          .catch(() => {
            routeProps.history.push("/error");
          });
      }
    };

    getUserProfileData();
  }, [routeProps.history, stringifiedMeasurements]);

  return (
    <div className="container">
      <Row style={{ backgroundColor: "#011e3b" }}>
        {/* TODO: add uncomment these two buttons once the functionality is fully functional */}
        {/* <Col xl={2} lg={2} md={4} sm={19} xs={17}>
          <CustomButton
            onClick={() => routeProps.history.push("/account")}
            ghost
            type="link"
            icon="user"
          >
            Mein Account
          </CustomButton>
        </Col>
        <Col xl={2} lg={2} md={4} sm={19} xs={17}>
          <CustomButton
            onClick={() => routeProps.history.push("/admin")}
            ghost
            type="link"
            icon="user"
          >
            Admin
          </CustomButton>
        </Col> */}
        <Col xl={2} lg={2} md={4} sm={19} xs={17}>
          <CustomButton
            ghost
            type="link"
            icon={<PoweroffOutlined />}
            onClick={() => handleLogoutClick()}
          >
            Logout
          </CustomButton>
        </Col>
      </Row>
      <Row style={{ backgroundColor: "#011e3b" }}>
        <Col xs={24}>
          <ContentWrapper>
            <img
              src={logoBig}
              alt="logo"
              width="50px"
              style={{ marginBottom: "40px", marginTop: "10px" }}
            />
          </ContentWrapper>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <ContentWrapper>
                <Title style={{ marginTop: "40px", marginBottom: "0" }}>
                  DEINE ONE FITTING ID
                </Title>
                {/* Not sure if API is missing here for the real feet
                of the customer or if here should stay this picture. */}
                <img
                  src={footGrid}
                  alt="foot"
                  width="200px"
                  style={{ marginTop: "20px", marginBottom: "40px" }}
                />
              </ContentWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
      {!isHidden ? (
        <>
          <Row>
            <Col xs={24}>
              <Row justify="end">
                <Col lg={7} md={7} xs={0}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <p>LINKS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={8} md={8} xs={0}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <p>RECHTS</p>
                  </ContentWrapper>
                </Col>
              </Row>
              <CustomRow justify="end" align="middle">
                <Col lg={3} md={3} xs={0}>
                  <ContentWrapper>
                    <img src={footLength} alt="length" width="75px" />
                  </ContentWrapper>
                </Col>

                <Col lg={4} md={4} xs={24}>
                  <TextWrapper>Länge in cm</TextWrapper>
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <br />
                </Col>
                <Col lg={0} md={0} xs={12}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <p>LINKS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={0} md={0} xs={12}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <p>RECHTS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={7} md={7} xs={12}>
                  <ContentWrapper>{measurements.leftFootLength}</ContentWrapper>
                </Col>
                <Col lg={8} md={8} xs={12}>
                  <ContentWrapper>
                    {measurements.rightFootLength}
                  </ContentWrapper>
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <br />
                </Col>
              </CustomRow>
              <CustomRow />
              <CustomRow justify="end">
                <Col lg={3} md={3} xs={0} />
                <Col lg={4} md={4} xs={24}>
                  <TextWrapper>Breite in cm</TextWrapper>
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <br />
                </Col>
                <Col lg={0} md={0} xs={12}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <p>LINKS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={0} md={0} xs={12}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <p>RECHTS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={7} md={7} xs={12}>
                  <ContentWrapper>{measurements.leftBallWidth}</ContentWrapper>
                </Col>
                <Col lg={8} md={8} xs={12}>
                  <ContentWrapper>
                    <p>{measurements.rightBallWidth}</p>
                  </ContentWrapper>
                  <CustomRow />
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <br />
                </Col>
                <Col lg={3} md={3} xs={0}>
                  <ContentWrapper>
                    <img src={footWidth} alt="length" width="75px" />
                  </ContentWrapper>
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <TextWrapper style={{ fontSize: "20px" }}>
                    Fußkategorie (Breite)
                  </TextWrapper>
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <br />
                </Col>
                <Col lg={4} md={4} xs={20} style={{ fontWeight: "normal" }}>
                  Sofern auch Daten für Schuhweiten vorliegen, berücksichtigen
                  wir diese in unseren Empfehlungen.
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <br />
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <p>LINKS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={7} md={7} xs={24}>
                  <ContentWrapper>
                    <Row justify="center">
                      <Col lg={12} md={12} xs={12}>
                        <Slider
                          marks={marks}
                          included={false}
                          value={measurements.leftWidthCategory}
                          min={1}
                          max={5}
                          tooltipVisible={false}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col
                        lg={12}
                        md={12}
                        xs={12}
                        style={{ fontWeight: "normal", fontSize: "14px" }}
                      >
                        <p>schmal</p>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        xs={12}
                        style={{ fontWeight: "normal", fontSize: "14px" }}
                      >
                        <p>breit</p>
                      </Col>
                    </Row>
                  </ContentWrapper>
                </Col>
                <Col lg={0} md={0} xs={24}>
                  <ContentWrapper
                    style={{ color: "#a0a0a0", textDecoration: "underline" }}
                  >
                    <br />
                    <p>RECHTS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <ContentWrapper>
                    <Row justify="center">
                      <Col xs={12} md={12} lg={12}>
                        <Slider
                          marks={marks}
                          included={false}
                          value={measurements.rightWidthCategory}
                          min={1}
                          max={5}
                          tooltipVisible={false}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col
                        lg={12}
                        md={12}
                        xs={12}
                        style={{ fontWeight: "normal", fontSize: "14px" }}
                      >
                        <p>schmal</p>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        xs={12}
                        style={{
                          fontWeight: "normal",
                          fontSize: "14px",
                          marginBottom: "60px",
                        }}
                      >
                        <p>breit</p>
                      </Col>
                    </Row>
                  </ContentWrapper>
                </Col>
              </CustomRow>
            </Col>
          </Row>
        </>
      ) : (
        // Display for no foot-data
        <div className="opacContainer">
          <RowWithOpacity>
            <Col xs={24}>
              <Row justify="end">
                <Col lg={7} md={7} xs={0}>
                  <ContentWrapper style={{ textDecoration: "underline" }}>
                    <p>LINKS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={8} md={8} xs={0}>
                  <ContentWrapper style={{ textDecoration: "underline" }}>
                    <p>RECHTS</p>
                  </ContentWrapper>
                </Col>
              </Row>
              <CustomRow justify="end" align="middle">
                <Col lg={3} md={3} xs={0}>
                  <ContentWrapper>
                    <img src={footLength} alt="length" width="75px" />
                  </ContentWrapper>
                </Col>
                <Col lg={4} md={4} xs={24}>
                  <TextWrapper>Länge in cm</TextWrapper>
                </Col>
                <Col lg={7} md={7} xs={0}>
                  <ContentWrapper>---</ContentWrapper>
                </Col>
                <Col lg={8} md={8} xs={0}>
                  <ContentWrapper>---</ContentWrapper>
                </Col>
              </CustomRow>
              {/* Mobile Only --> */}
              <CustomRow>
                <Col lg={0} md={0} xs={12}>
                  <ContentWrapper style={{ textDecoration: "underline" }}>
                    <p>LINKS</p>
                  </ContentWrapper>
                </Col>
                <Col lg={0} md={0} xs={12}>
                  <ContentWrapper style={{ textDecoration: "underline" }}>
                    <p>RECHTS</p>
                  </ContentWrapper>
                </Col>
              </CustomRow>
              {/* <-- Mobile Only */}
            </Col>
          </RowWithOpacity>
          <Row justify="center" align="middle">
            <Col
              lg={{ span: 4, order: 1 }}
              md={{ span: 5, order: 1 }}
              xs={{ order: 2 }}
            >
              <Row>
                <Col lg={23} md={23}>
                  <a href="https://apps.apple.com/de/app/onefid-scan/id1473932725?ls=1">
                    <img
                      src={iosAppStore}
                      alt="apple"
                      width="140px"
                      style={{ margin: "5px" }}
                    />
                  </a>
                </Col>
              </Row>
              <Row>
                <Col lg={23} md={23}>
                  <a href="https://play.google.com/store/apps/details?id=com.onefid.scan">
                    <img
                      src={androidAppStore}
                      alt="google"
                      width="140px"
                      style={{ margin: "5px" }}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <CustomCol
              lg={{ span: 12, order: 2 }}
              md={{ span: 12, order: 2 }}
              xs={{ span: 20, order: 1 }}
              style={{ fontWeight: "normal" }}
            >
              Leider haben wir von dir noch keine Fußdaten in 2D oder 3D
              Qualität. Lade unsere Scanning App und vermesse deine Füße mit
              Hilfe deines Smartphones bequem von zu Hause aus.
            </CustomCol>
          </Row>
          <CustomRow justify="center" align="middle">
            <Col lg={12} md={12} xs={16}>
              <Divider />
            </Col>
          </CustomRow>
        </div>
      )}

      <Row>
        <Col xs={24}>
          <Row justify="center">
            <Col lg={12} md={12} xs={24}>
              <ContentWrapper style={{ marginBottom: "60px" }}>
                Unsere Algorithmen verknüpfen deine Fußdaten mit unseren
                Schuhdaten und verraten dir, welche Schuhe am besten passen.
                <br /> <br />
                Deine One Fitting ID
                <br />
                ist in einem wachsenden Netzwerk von Partnern einsetzbar.
              </ContentWrapper>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={13} md={13} xs={24}>
              <ContentWrapper style={{ marginBottom: "50px" }}>
                <BottomText>
                  Finde die passenden Schuhe für deine Füße in unserem Shop mit
                  über 50’000 Produkten. Lade die App zum Shop hier herunter.
                </BottomText>
              </ContentWrapper>
            </Col>
          </Row>
          <Row justify="center">
            <ContentWrapper style={{ marginBottom: "50px" }}>
              <Col lg={12} md={12} xs={24} style={{ padding: "5px 0" }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.onefid.scan"
                  style={{ padding: "0 5px" }}
                >
                  <img src={androidAppStore} alt="google" width="140px" />
                </a>
              </Col>
              <Col lg={12} md={12} xs={24} style={{ padding: "5px 0" }}>
                <a
                  href="https://apps.apple.com/de/app/onefid-scan/id1473932725?ls=1"
                  style={{ padding: "0 5px" }}
                >
                  <img src={iosAppStore} alt="apple" width="140px" />
                </a>
              </Col>
            </ContentWrapper>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Footer />
        </Col>
      </Row>
    </div>
  );
};
