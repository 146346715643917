// Page when Activationlink expired
import { Form, Col, Row } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { BlueButton } from "../components/Buttons/BlueButton";
import { CustomButton } from "../components/Buttons/Button";
import { CustomInput } from "../components/CustomInput";
import { PopupLayout } from "../components/PopupLayout";
import { ContentWrapper, Text, Title } from "../components/TextLayout";

// Page appears when the activation link is expired
// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD

export const ExpiredLink = (): JSX.Element => {
  const [isHidden, setIsHidden] = useState(false);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: 11,
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 24,
      },
    },
  };

  const handleSubmit = () => {
    setIsHidden(true);
  };

  return (
    <PopupLayout>
      {!isHidden ? (
        <>
          <Row>
            <Col
              xs={24}
              md={24}
              sm={24}
              lg={24}
              xl={24}
              style={{ textAlign: "left", margin: "0 3%" }}
            >
              <Title style={{ marginBottom: 0 }}>
                DAS HAT LEIDER
                <br />
                NICHT FUNKTIONIERT
              </Title>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              md={24}
              sm={24}
              lg={24}
              xl={24}
              style={{ textAlign: "left", margin: "0 3%" }}
            >
              <Row>
                <Col xs={24} md={24} sm={24} lg={24} xl={24}>
                  <Text style={{ paddingTop: "10px" }}>
                    Dein Aktivierungslink ist abgelaufen
                    <br />
                    und nicht mehr gültig.
                    <div style={{ marginTop: "15px" }}>
                      Möchtest du einen neuen &nbsp;
                      <strong>Aktivierungslink</strong> erhalten?
                    </div>
                  </Text>
                </Col>
              </Row>
              <Form {...formItemLayout} onFinish={handleSubmit} name="expired">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Das ist keine korrekte E-Mail Adresse.",
                    },
                    {
                      required: true,
                      message: "Bitte gib deine E-Mail Adresse ein.",
                    },
                  ]}
                >
                  <CustomInput className="EmailBar" placeholder="E-Mail" />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <BlueButton htmlType="submit" className="ButtonMargin">
                    SENDEN
                  </BlueButton>
                  <a href="/">
                    <CustomButton>ABBRECHEN</CustomButton>
                  </a>
                </Form.Item>
              </Form>
              <br /> <br />
            </Col>
          </Row>
        </>
      ) : (
        <ContentWrapper>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <Text className="MailGesendet" style={{ paddingTop: "10px" }}>
                Wir haben dir eine neue E-Mail gesendet. Bitte schaue in deinem
                Postfach nach und klicke auf den Link um dein One Fitting ID
                Passwort zu setzen.
              </Text>
            </Col>
          </Row>
        </ContentWrapper>
      )}
    </PopupLayout>
  );
};
