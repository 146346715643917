import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";
import { ErrorPage } from "../pages/ErrorPage";
import { parseQueryParams } from "../utils/Helper";

export const Activation = (routeProps: RouteComponentProps): JSX.Element => {
  const verificationParams = {
    userId: 0,
    timestamp: 0,
    signature: "",
  };
  const { location } = routeProps;
  const parsedParams = parseQueryParams(location.search);
  const { user_id: userId, timestamp, signature } = parsedParams;

  if (userId) {
    verificationParams.userId = userId;
  }

  if (timestamp) {
    verificationParams.timestamp = timestamp;
  }

  if (signature) {
    verificationParams.signature = signature;
  }

  // TODO: redirect to expired signature or already activated pages when api support is added
  getApiService()
    .postActivateUser({ ...verificationParams })
    .then((response) => {
      if (response.status === 200) {
        routeProps.history.push("/activation-done/");
      }
    })
    .catch(() => {
      routeProps.history.push("/error/");
    });

  return <ErrorPage />;
};
