import styled from "@emotion/styled";
import React from "react";
import { CustomButton } from "./Button";

export const BlueButton = styled(({ children, ...props }) => (
  <CustomButton {...props}>{children}</CustomButton>
))({
  backgroundColor: "#001f3b",
  color: "#fff",
});
