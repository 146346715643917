import React, { useEffect } from "react";
import ReactGA, { FieldsObject } from "react-ga";
import { RouteComponentProps } from "react-router-dom";

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {}
) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    const { location } = props;
    useEffect(() => {
      trackPage(location.pathname);
    }, [location.pathname]);

    return <WrappedComponent {...props} />;
  };
};

/**
 * GaEventTracker - Add custom tracking event.
 * @param {string} category A top level category for these events
 * @param {string} action A description of the behavior
 * @param {string} label More precise labelling of the related action
 * @param {boolean} nonInteraction If an event is not triggered by a user interaction, but instead by our code
 */
export const GaEventTracker = (
  category: string,
  action: string,
  label?: string,
  nonInteraction?: boolean
): void => {
  ReactGA.event({
    category,
    action,
    label,
    nonInteraction,
  });
};
