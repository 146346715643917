// Page when Activationlink expired
import { Col, Form, Row } from "antd";
import "antd/dist/antd.css";
import { StoreValue } from "antd/lib/form/interface";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";
import { BlueButton } from "../components/Buttons/BlueButton";
import { CustomButton } from "../components/Buttons/Button";
import { CustomInput } from "../components/CustomInput";
import { PopupLayout } from "../components/PopupLayout";
import { Spinner } from "../components/Spinner";
import { Text, Title } from "../components/TextLayout";

// This Page is needed for the user to set a password after he registered via a scanner
// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD

export const SetPassword = (routeProps: RouteComponentProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [signature, setSignature] = useState("");
  const [userId, setUserId] = useState("");
  const [timestamp, setTimeStamp] = useState("");
  const [initialRegister, setInitialRegister] = useState(false);

  const onFinish = async (value: StoreValue) => {
    try {
      await (initialRegister
        ? getApiService().postSetInitialPasswordAndActivate(
            { userId, signature, timestamp },
            value.password
          )
        : getApiService().postResetPassword(
            { userId, signature, timestamp },
            value.password
          ));
      routeProps.history.push("/password-reset-complete");
    } catch {
      routeProps.history.replace("/server-error");
    }
  };

  useEffect(() => {
    const parseUrl = (): URLSearchParams | void => {
      const { search } = routeProps.location;
      if (search) {
        return new URLSearchParams(search);
      }

      return console.error("Error: No activation signature in url parameters");
    };

    // Main
    const params = parseUrl();
    if (!params) {
      routeProps.history.replace("/error");

      return;
    }

    const uid = params.get("user_id");
    const tst = params.get("timestamp");
    const sgn = params.get("signature");
    const iniReg = params.get("initial_register");
    if (!uid || !tst || !sgn) {
      console.error("Error: Could not parse activation signature");

      routeProps.history.replace("/error");

      return;
    }
    setUserId(uid);
    setTimeStamp(tst);
    setSignature(sgn);
    if (iniReg) setInitialRegister(true);

    setIsLoading(false);
  }, [routeProps]);

  if (isLoading)
    return (
      <PopupLayout>
        <Spinner />
      </PopupLayout>
    );

  return (
    <PopupLayout>
      <Row>
        <Col xs={24} style={{ textAlign: "left", margin: "0 3%" }}>
          <Title style={{ marginBottom: 0 }}>
            DEIN ONE FITTING <br />
            ID PASSWORT
          </Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24} style={{ textAlign: "left", margin: "0 3%" }}>
          <Row>
            <Col xs={24}>
              <Text style={{ paddingTop: "10px" }}>
                Bitte lege hier dein Passwort fest.
              </Text>
            </Col>
          </Row>
          <Form
            labelCol={{ xs: 24 }}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 12,
              lg: 10,
            }}
            onFinish={onFinish}
            name="set-password"
          >
            <Form.Item
              hasFeedback
              name="password"
              rules={[
                {
                  required: true,
                  message: "Bitte gib dein neues Passwort ein.",
                },
                {
                  required: true,
                  // the regex pattern below checks if:
                  // Your password is at least 8 characters long.
                  // Your password contains at least one number.
                  // Your password contains at least one letter.
                  // Your password contains at least one special character
                  pattern: /^(?=\S*[A-Za-z])(?=\S*\d)(?=\S*[^\s\w])\S{8,}$/,
                  message:
                    "Das Passwort muss mindestens 8 Zeichen, Buchstaben, Zahlen und Sonderzeichen enthalten.",
                },
              ]}
            >
              <CustomInput type="Password" placeholder="Passwort" />
            </Form.Item>
            <Form.Item
              hasFeedback
              name="confirm"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Bitte bestätige dein Passwort.",
                },
                {
                  required: true,
                  pattern: /^(?=\S*[A-Za-z])(?=\S*\d)(?=\S*[^\s\w])\S{8,}$/,
                  message:
                    "Das Passwort muss mindestens 8 Zeichen, Buchstaben, Zahlen und Sonderzeichen enthalten.",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value && getFieldValue("password") !== value) {
                      return Promise.reject(
                        new Error(
                          "Die beiden eingegebenen Passwörter sind nicht identisch!"
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <CustomInput type="Password" placeholder="Passwort bestätigen" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: 24,
              }}
            >
              <BlueButton htmlType="submit" className="ButtonMargin">
                WEITER
              </BlueButton>
              <CustomButton onClick={() => routeProps.history.push("/")}>
                ABBRECHEN
              </CustomButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </PopupLayout>
  );
};
