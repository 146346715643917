import styled from "@emotion/styled";
import { Col, Collapse, Layout, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { AccountFooter } from "../components/AccountFooter";
import { AccountHeader } from "../components/AccountHeader";

const Content = styled(Layout.Content)({
  textAlign: "center",
  fontSize: "14px",
  backgroundColor: "white",
});

const Title = styled.h1({
  fontSize: "35px",
  fontWeight: 800,
  "@media (max-width: 768px)": {
    fontSize: "26px",
  },
});

const Subtitle = styled.h3({
  fontSize: "20px",
  "@media (max-width: 768px)": {
    fontSize: "17px",
  },
});

const CustomRow = styled(Row)({
  marginTop: "60px",
  marginBottom: "30px",
});

const { Panel } = Collapse;

// This is used for the line breaks in the questionAnswersObject via \n
const addLineBreaks = (questionsAnswersObject: string) =>
  questionsAnswersObject.split("\n").map((key: React.ReactNode) => (
    <React.Fragment key={`question: ${key}`}>
      {key}
      <br />
    </React.Fragment>
  ));

const questionsAnswersObject: { [key: string]: string } = {
  "Wie kann ich eine One Fitting ID erstellen?":
    "Am einfachsten erstellst du deine One Fitting ID mit Hilfe der Maßband App. Auf diesem Wege erstellst du nämlich auch noch gleich deinen Fußscan. Ein weiterer Weg ist die Erstellung deiner One Fitting ID bei unseren Partnern mit 2D oder 3D Vermessungsstation.",
  "Bei welchen Partnern stehen 3D Scanner?":
    "Wir aktualisieren die Mitglieder unseres Partnernetzwerkes regelmäßig auf unserer Homepage.",
  "Warum sollte ich meine Füße vermessen? Ich kann doch auch meine Lieblingsschuhe eingeben?":
    "Mit Hilfe deines Lieblingschuhs können wir eine erste gute Größenempfehlung für deinen nächsten Schuhkauf geben. Mit einem Fußscan wird das Ergebnis jedoch viel genauer, da wir deine Füße in Länge und Breite millimetergenau vermessen.",
  "Welche Daten werden mit meiner One Fitting ID gespeichert? ":
    "Zusammen mit der One Fitting ID werden folgende Daten gespeichert:\n- Fuß- oder Lieblingsschuhdaten\n- E-Mail Adresse\n- Geschlecht\n- Evtl. Name",
  "Wie kann ich mir meine Daten wieder anschauen? ":
    "Unter id.onefid.com kannst du jederzeit dein Fußprofil anschauen und deine persönlichen Daten ändern.",
  "Ist die Erstellung einer One Fitting ID kostenfrei? ":
    "Ja, die Erstellung deiner One Fitting ID ist für dich kostenfrei. Auch für die Größenempfehlung beim Partner zahlst du keine Gebühren.",
  "Muss man die OneFID ID irgendwann erneuern? ":
    "Nein, die Daten werden für einen unbegrenzten Zeitpunkt gespeichert. Wir empfehlen, aber nach 5 Jahren eine neue Vermessung vorzunehmen.",
};

export const FAQ: React.FC = (): JSX.Element => (
  <Layout>
    <AccountHeader />
    <Content>
      <CustomRow>
        <Col xs={24}>
          <Title>Fragen? wir helfen dir Weiter.</Title>
          <Subtitle>
            Die richtige Antwort ist nicht dabei?
            <br />
            Stelle uns deine Frage unter &nbsp;
            <a href="mailto: support@onefid.com">
              <strong style={{ color: "black", fontSize: "18px" }}>
                support@onefid.com
              </strong>
            </a>
          </Subtitle>
        </Col>
      </CustomRow>
      <Row style={{ marginBottom: "60px" }}>
        <Col offset={2} xs={20}>
          <Collapse bordered={false}>
            {Object.keys(questionsAnswersObject).map((value: string) => {
              return (
                <Panel header={value} key={`question:${value}`}>
                  <Col
                    offset={2}
                    xs={20}
                    style={{ color: "#4a4a4a", marginBottom: "15px" }}
                  >
                    {addLineBreaks(questionsAnswersObject[value])}
                  </Col>
                </Panel>
              );
            })}
          </Collapse>
        </Col>
      </Row>
    </Content>
    <AccountFooter />
  </Layout>
);
