import styled from "@emotion/styled";
import { Button as AntdButton } from "antd";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick?: () => void;
  type?: string;
  htmlType?: string;
};

export const CustomButton = styled(({ children, ...props }) => (
  <AntdButton {...props}>{children}</AntdButton>
))({
  width: "12rem",
  height: "2.5rem",
  marginRight: "15px",
  marginBottom: "15px",
  color: "black",
  fontSize: "12px",
  fontWeight: "bold",
  backgroundColor: "#fff",
  borderColor: "#001f3b",
  borderRadius: "40px",
  textTransform: "uppercase",
});

export const Button = ({ children, ...props }: Props): JSX.Element => {
  return <CustomButton {...props}>{children}</CustomButton>;
};
