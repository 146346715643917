import styled from "@emotion/styled";

export const ParagraphTitle = styled.p({
  textAlign: "center",
  marginBottom: "0",
  lineHeight: "1,5",
  fontSize: "8vw",
  fontWeight: 900,
  minWidth: 250,
  textShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
});
