import { CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button } from "antd";
import "antd/dist/antd.css";
import React from "react";
// Xbutton on the popup pages

const XButtonWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  float: "right",
  paddingRight: "1vh",
  paddingTop: "1vh",
  "@media (max-width: 786px)": {
    display: "none",
  },
});

export const XButton: React.FC = (): JSX.Element => (
  <XButtonWrapper>
    <a href="/">
      <Button
        className="Closebutton"
        shape="circle"
        type="link"
        icon={<CloseOutlined />}
        style={{
          color: "black",
          zIndex: 3,
          fontSize: "5vh",
          height: "10vh",
          width: "10vh",
        }}
      />
    </a>
  </XButtonWrapper>
);
