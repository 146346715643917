import styled from "@emotion/styled";
import { Col, Modal, Row } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { ModalLayout } from "../LayoutModals";
import { BlueButton } from "./BlueButton";
// Modal for changing the foot data ->New Scan

const handleSubmit = (e: Event) => {
  e.preventDefault();
};

const PopupButton = (): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => {
    setIsVisible(true);
  };

  const handleOk = () => {
    setIsVisible(false);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <BlueButton type="primary" onClick={showModal}>
        Bearbeiten
      </BlueButton>
      <Modal visible={isVisible} onOk={handleOk} onCancel={handleCancel}>
        <Row>
          <Col>
            <p style={{ fontSize: "16px" }}>
              <strong>Placeholder</strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{ marginBottom: "5px" }}>
              <strong>Placeholder</strong>
            </p>
            <ModalLayout handleSubmit={handleSubmit} />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export const ModalButtonFootData = styled(({ children, ...props }) => (
  <PopupButton {...props}>{children}</PopupButton>
))({});
