import styled from "@emotion/styled";
import { Input } from "antd";

export const CustomInput = styled(Input)({
  borderLeft: "none",
  borderRight: "none",
  borderTop: "none",
  borderRadius: 0,
  width: "400px",
  "@media (max-width: 576px)": {
    width: "300px",
  },
});
