import styled from "@emotion/styled";
import { Col, Layout, Row } from "antd";
import "antd/dist/antd.css";
import React, { ReactNode } from "react";
import { activation, backgroundAp, footerLogo, logoAp } from "../assets";
import { XButton } from "./Buttons/XButton";
import {
  BackgroundWrapper,
  CopyWrapper,
  FooterWrapper,
  MainWrapper,
  Wrapper,
} from "./LayoutWrapper";
import { FooterText } from "./TextLayout";

// This file contains all layout and design stuff for the popup pages
// (so as activation-success,...,login,register...)
type Props = {
  readonly children?: ReactNode;
};

const CustomLayout = styled(Layout)({
  backgroundColor: "White",
});

export const PopupLayout = ({ children }: Props): JSX.Element => (
  <BackgroundWrapper style={{ overflow: "hidden" }}>
    <MainWrapper>
      <div className="popUp">
        <XButton />

        <Wrapper>
          <img src={backgroundAp} className="BackgroundAP" alt="" />
          <img src={activation} className="Activation" alt="" />
        </Wrapper>
        <CustomLayout>
          <div className="ScrollableBox">
            <Row align="middle">
              <Col xs={24} md={24} sm={24} lg={24} xl={24}>
                <img
                  src={logoAp}
                  width="55px"
                  alt=""
                  style={{ margin: "1% 3%" }}
                />
              </Col>
            </Row>
            {children}
          </div>
        </CustomLayout>
      </div>
      <img src={footerLogo} className="LogoMobil" width="auto" alt="" />
      <FooterWrapper>
        <CustomLayout style={{ backgroundColor: "#ebebeb" }}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={24} md={24} lg={10} xl={12}>
              <CopyWrapper>
                <FooterText>
                  Copyright © 2019 OneFID. Alle Rechte vorbehalten.
                </FooterText>
              </CopyWrapper>
            </Col>
            <Col xs={0} sm={0} md={0} lg={13} xl={11}>
              <img
                src={footerLogo}
                className="footerLogo"
                width="auto"
                alt=""
              />
            </Col>
            <Col xs={0} sm={0} md={0} lg={1} xl={1} />
          </Row>
        </CustomLayout>
      </FooterWrapper>
    </MainWrapper>
  </BackgroundWrapper>
);
