// Page when PW was already set
import { Col, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BlueButton } from "../components/Buttons/BlueButton";
import { PopupLayout } from "../components/PopupLayout";
import {
  ContentWrapper,
  Minortext,
  Text,
  Title,
} from "../components/TextLayout";

// Page appears when the user already has set the password
// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD

export const AlreadySetPw = (routeProps: RouteComponentProps): JSX.Element => (
  <PopupLayout>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper style={{ marginBottom: "0" }}>
          <Title style={{ marginBottom: 0 }}>
            DAS HAT LEIDER
            <br />
            NICHT FUNKTIONIERT
          </Title>
        </ContentWrapper>
      </Col>
    </Row>
    <ContentWrapper>
      <Row>
        <Col xs={24} md={24} sm={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <Text style={{ paddingTop: "10px" }}>
                Vielleicht hast du dein Passwort bereits gesetzt?
                <br />
                Dann klicke auf Weiter.
              </Text>
            </Col>
          </Row>
          <BlueButton onClick={() => routeProps.history.push("/login/")}>
            WEITER
          </BlueButton>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <Minortext style={{ paddingTop: "10px" }}>
                Wenn hier ein Fehler passiert ist und du noch kein Passwort
                gesetzt hast,
                <br />
                Dann schreib uns unter &nbsp;
                <a
                  href="mailto: info@onefid.com"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  info@onefid.com
                </a>
                <br />
                Wir schicken dir einen neuen Aktivierungslink.
              </Minortext>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContentWrapper>
  </PopupLayout>
);
