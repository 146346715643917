import styled from "@emotion/styled";
// In this file all of the used Text stylings is bundled
export const FooterText = styled.p({
  marginTop: "1em",
  fontSize: "14.64px",
  "@media (max-width: 786px)": {
    fontSize: "10.98px",
    textAlign: "center",
    padding: "2%",
  },
});
export const Title = styled.h1({
  color: "black",
  fontSize: "35px",
  fontWeight: 800,
  "@media (max-width: 786px)": {
    marginLeft: "0%",
    fontSize: "28px",
    textAlign: "center",
  },
});
export const ContentWrapper = styled.div({
  textAlign: "left",
  margin: "1% 3%",
  "@media (max-width: 786px)": {
    textAlign: "center",
  },
});
export const Text = styled.p({
  color: "black",
  fontSize: "14px",
  "@media (max-width: 786px)": {
    marginLeft: "0%",
    fontSize: "11px",
    textAlign: "center",
  },
});
export const Minortext = styled.p({
  color: "black",
  fontSize: "12px",
  "@media (max-width: 786px)": {
    marginLeft: "0%",
    fontSize: "9px",
    textAlign: "center",
  },
});
export const ErrorMessageText = styled.p({
  margin: "0 55px 25px",
  lineHeight: "19px",
});
