import { Col, Input, Modal, Radio, Row } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { ModalLayout } from "../LayoutModals";
import { BlueButton } from "./BlueButton";
// Modal for personal information such as: Gender, Date of birth, Name...

const handleSubmit = (e: Event) => {
  e.preventDefault();
};

export const PopupButton = (): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => {
    setIsVisible(true);
  };

  const handleOk = () => {
    setIsVisible(false);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <BlueButton type="primary" onClick={showModal}>
        Bearbeiten
      </BlueButton>
      <Modal visible={isVisible} onOk={handleOk} onCancel={handleCancel}>
        <Row>
          <Col>
            <p style={{ fontSize: "16px" }}>
              <strong>Nutzerdaten aktualisieren</strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{ marginBottom: "5px" }}>
              <strong>Vorname</strong>
            </p>
            <Input
              className="firstName"
              placeholder="Placeholder"
              name="android:fitsSystemWindows"
            />
            <p style={{ marginTop: "15px", marginBottom: "5px" }}>
              <strong>Nachname</strong>
            </p>
            <Input
              className="lastName"
              placeholder="Placeholder"
              name="android:fitsSystemWindows"
            />
            <p style={{ marginTop: "15px", marginBottom: "5px" }}>
              <strong>Geburtsdatum</strong>
            </p>

            <p style={{ marginTop: "15px", marginBottom: "5px" }}>
              <strong>Geschlecht</strong>
            </p>
            <Radio.Group>
              <Radio value={1}>Weiblich</Radio>
              <Radio value={2}>Männlich</Radio>
            </Radio.Group>
            <ModalLayout handleSubmit={handleSubmit} />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
