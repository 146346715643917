import styled from "@emotion/styled";
import { Col, Layout, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  blackShoeBox,
  errorNotFoundBackground,
  logoAp,
  silverShoeBox,
} from "../assets";
import { BlueButton } from "../components/Buttons/BlueButton";
import { CustomButton } from "../components/Buttons/Button";
// Page appears when the page the user is searching is not found
const ContentWrapper = styled.div({
  textAlign: "left",
  "@media(max-width: 768px)": {
    textAlign: "center",
    marginTop: "10%",
    marginBottom: "10%",
  },
});

const CustomLayout = styled(Layout)({
  backgroundImage: `url(${errorNotFoundBackground})`,
  objectFit: "cover",
  minHeight: "100vh",
});

const Title = styled.h1({
  // only for the 404
  color: "black",
  fontSize: "200px",
  fontWeight: 800,
  textAlign: "center",
  lineHeight: "200px",
  "@media (max-width: 786px)": {
    fontSize: "80px",
    lineHeight: "60px",
  },
});

const Subtitle = styled.h2({
  color: "black",
  fontSize: "28px",
  fontWeight: 800,
  textAlign: "center",
  lineHeight: "50px",
  "@media (max-width: 786px)": {
    fontSize: "20px",
    lineHeight: "20px",
    margin: "5%",
  },
  "@media (max-width: 576px)": {
    fontSize: "18px",
  },
});

const Text = styled.p({
  color: "black",
  fontSize: "16px",
  textAlign: "center",
  lineHeight: "32px",
  marginLeft: "2%",
  marginRight: "2%",
  "@media (max-width: 576px)": {
    fontSize: "11px",
    lineHeight: "20px",
    margin: "5%",
  },
});

export const Error404 = (props: RouteComponentProps): JSX.Element => {
  const { ...routeProps } = props;

  return (
    <CustomLayout>
      <Row align="middle" style={{ margin: "2%" }}>
        <Col xs={24} md={24} sm={24} lg={24} xl={24}>
          <ContentWrapper>
            <img src={logoAp} width="55px" alt="" />
          </ContentWrapper>
        </Col>
      </Row>
      <Row>
        <Col xs={0} sm={0} md={0} lg={6} xl={6}>
          <img src={blackShoeBox} width="200px" alt="black shoes" />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <Title style={{ margin: 0 }}>404</Title>
              <Subtitle> DAS HAT LEIDER NICHT GEKLAPPT</Subtitle>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <Text>
                Scheinbar wurde diese Seite verschoben oder sie ist nicht mehr
                verfügbar.
                <br />
                Was möchtest du als nächstes tun?
              </Text>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <ContentWrapper
                style={{ textAlign: "center", margin: 0, marginTop: "15px" }}
              >
                <BlueButton
                  onClick={() => routeProps.history.push("/")}
                  style={{ minWidth: "267px", height: "35px" }}
                >
                  bring mich zur startseite
                </BlueButton>
              </ContentWrapper>
            </Col>
          </Row>

          <Row align="middle" justify="center">
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <ContentWrapper style={{ textAlign: "center", margin: 0 }}>
                <CustomButton
                  onClick={() => routeProps.history.goBack()}
                  style={{ minWidth: "267px", height: "35px" }}
                >
                  bring mich zur vorherigen seite
                </CustomButton>
              </ContentWrapper>
            </Col>
          </Row>
        </Col>

        <Col
          xs={0}
          sm={0}
          md={0}
          lg={6}
          xl={6}
          style={{ textAlign: "right", overflow: "hidden" }}
        >
          <img
            src={silverShoeBox}
            width="300px"
            style={{ marginTop: "30vh" }}
            alt="sliver shoes"
          />
        </Col>
      </Row>
    </CustomLayout>
  );
};
