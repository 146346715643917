// Page when Account Activation succeeded:
import { Col, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BlueButton } from "../components/Buttons/BlueButton";
import { PopupLayout } from "../components/PopupLayout";
import { ContentWrapper, Text, Title } from "../components/TextLayout";

// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD
export const ActivationDone = (
  routeProps: RouteComponentProps
): JSX.Element => (
  <PopupLayout>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper style={{ marginBottom: "0" }}>
          <Title>GRATULATION!</Title>
        </ContentWrapper>
      </Col>
    </Row>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper>
          <Text style={{ paddingTop: "10px" }}>
            Du hast soeben erfolgreich deine
            <br />
            <strong>One Fitting ID</strong> aktiviert.
          </Text>
        </ContentWrapper>
      </Col>
    </Row>
    <ContentWrapper>
      <BlueButton onClick={() => routeProps.history.push("/login/")}>
        START
      </BlueButton>
    </ContentWrapper>
  </PopupLayout>
);
