// Page when Account Activation succeeded:
import { Col, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { BlueButton } from "../components/Buttons/BlueButton";
import { PopupLayout } from "../components/PopupLayout";
import { ContentWrapper, Text, Title } from "../components/TextLayout";

// Subdivision of the page into different wrappers. Determination of the content by individual classes. Layout: using AntD

export const ErrorPage: React.FC = (): JSX.Element => (
  <PopupLayout>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper style={{ marginBottom: "0" }}>
          <Title style={{ marginBottom: 0 }}>
            DAS HAT LEIDER
            <br />
            NICHT FUNKTIONIERT
          </Title>
        </ContentWrapper>
      </Col>
    </Row>
    <Row>
      <Col xs={24} md={24} sm={24} lg={24} xl={24}>
        <ContentWrapper>
          <Row>
            <Col xs={24} md={24} sm={24} lg={24} xl={24}>
              <Text style={{ paddingTop: "10px" }}>
                Scheinbar ist hier ein Fehler aufgetreten.
                <br />
                Bitte kontaktiere uns unter &nbsp;
                <strong>info@onefid.com</strong>.
                <br />
                Wir werden dir helfen dieses Problem zu beheben.
              </Text>
            </Col>
          </Row>
          <a href="/">
            <BlueButton>ZURÜCK</BlueButton>
          </a>
        </ContentWrapper>
      </Col>
    </Row>
  </PopupLayout>
);
